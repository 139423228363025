import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { SoundToggle } from './SoundToggle';
import { LanguageSelector } from './LanguageSelector';

const Hero = () => {
    const page = document.querySelector('.l-page');
    const element = document.querySelector('.js-hero');
    const logo = [...element.querySelectorAll('.js-hero-logo svg')];
    const logoChars = [
        ...element.querySelectorAll('.js-hero-logo g.logo path'),
    ];
    const logoTexts = [...element.querySelectorAll('.js-hero-logo g.text')];
    const buttons = [...element.querySelectorAll('.js-hero-btn')];
    const texts = [...element.querySelectorAll('.js-hero-text')];
    const video = element.querySelector('.js-hero-video');
    const navigation = document.querySelector('.js-navigation');
    const footer = document.querySelector('.js-footer');

    const enableVideo = () => {
        video.play();
        video.addEventListener('ended', () => {
            video.currentTime = 25;
            video.play();
        });
    };

    const enter = () => {
        enableVideo();

        SoundToggle();
        LanguageSelector();

        const timeline = gsap.timeline({ delay: 0.75 });

        timeline
            .set(page, { opacity: 1, pointerEvents: 'all' })
            .set(logo, { yPercent: 80, scale: 3 })
            .to(
                [navigation, footer],
                {
                    opacity: 1,
                    pointerEvents: 'all',
                    duration: 0.25,
                    ease: 'flicker',
                },
                '<'
            )
            .from(
                logoChars,
                {
                    opacity: 0,
                    duration: 0.4,
                    stagger: { amount: 0.2, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            )
            .set(logo, { yPercent: 0, scale: 1 }, '-=.39')
            .from(logoTexts, {
                opacity: 0,
                duration: 0.25,
                stagger: { amount: 0.2 },
                ease: 'flicker',
            })
            .from(
                buttons,
                {
                    opacity: 0,
                    duration: 0.25,
                    stagger: { amount: 0.2, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            );

        texts.forEach((text) => {
            const split = new SplitText(text, {
                type: 'lines,chars',
                tag: 'span',
            });

            timeline.fromTo(
                split.chars,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 0.3,
                    stagger: { amount: 0.3, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            );

            timeline.fromTo(
                split.lines,
                {
                    xPercent: (index) => (index ? 20 : -20),
                },
                {
                    xPercent: 0,
                    duration: 0.3,
                    stagger: { amount: 0.2, from: 'random' },
                },
                '<'
            );

            split.chars.forEach((char) => {
                const letter = char.textContent;
                char.addEventListener('mouseenter', () => {
                    gsap.to(char, {
                        scrambleText: {
                            text: letter,
                            speed: 10,
                            chars: '^%#$&*((_)HTJFCYYVGHKBL',
                            revealDelay: 0.25,
                        },
                    });
                });
            });
        });

        return timeline;
    };

    return { enter };
};

export { Hero };
